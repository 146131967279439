.static {
	display: block;
}

.inner-banner {
	background-image: url(RESOURCE/img/inner-banner-1.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 390px;
	position: relative;

	@media (max-width:992px) {
		height: 320px;
	}

	.page-title {
		position: absolute;
		bottom: 70px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;

		@media (max-width:992px) {
			bottom: 30px;
		}


		h1 {
			font-size: 42px;
			margin-bottom: 0;
			color: var(--white);
			text-transform: uppercase;

			@media (max-width:992px) {
				font-size: 30px;
			}
		}

	}
}

.heading1 {
	font-size: 42px;

	@media (max-width: 575px) {
		font-size: 30px;
	}

	&.brk-title {
		@media (max-width: 575px) {
			word-break: break-all;
		}
	}
}


.teams-bl {
	max-width: 1040px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.team-bl {
		border: 1px solid #e8e8e8;

		.team-dt {

			border-top: none;
			padding: 20px 8px;
			text-align: center;
		}
	}
}


.contact-page {
	.control-label {
		text-align: left;
		padding-bottom: 5px;
	}

	.form-control {
		border: none;
		border-bottom: 2px solid #f2f2f2;
		text-transform: uppercase;
		margin-bottom: 30px;
		border-radius: 0;
		box-shadow: inherit;
		padding: 0 7px;
	}


	label {
		color: var(--black);
		font-weight: 700;
		font-size: 14px;
		text-transform: uppercase;
		margin-bottom: 0;
		padding-bottom: 10px;
	}

	.checkbox {
		label {
			text-transform: inherit;
			font-weight: normal;
		}

		a {
			color: var(--black);
			font-size: 14px;
		}

		.privacy-link {
			position: relative;
			top: 0;
		}

	}

	.btn-go {
		box-shadow: 2px 1px 10px rgba(0, 0, 0, .2);
		font-size: 20px;
	}
}

.first-box-content {
	.img {
		margin-bottom: 30px;

		img {
			width: 100%;
		}

	}
}

.interesting-places {
	.box {
		border: 1px solid #e1e1e1;
		padding: 5px;
		margin-bottom: 20px;
		background: #fff;

		.img-section {
			img {
				width: 100%;
			}
		}

		.text-section {
			padding: 25px 15px;

			h4 {
				margin: 0 0 10px 0;
				text-transform: uppercase;
			}

			h5 {
				position: relative;
				padding: 0 0 0 20px;
				line-height: 22px;
				margin: 0 0 15px 0;

				i {
					position: absolute;
					top: 3px;
					left: 0;
				}
			}

			p {
				margin-bottom: 15px;
			}

			h6 {
				margin: 0;
				font-size: 16px;
				color: #333;
				background: url(RESOURCE/img/location-icon.png);
				background-repeat: no-repeat;
				padding: 0 0 0 20px;
				background-position: 0 4px;
				line-height: 22px;
				word-break: break-word;

				a {
					color: #333;
					text-decoration: underline;
				}
			}

		}

	}
}

.just-beautiful {
	position: relative;
	margin-top: 100px;
	padding-top: 100px !important;

	&:before {
		position: absolute;
		top: 0;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		content: '';
		display: block;
		height: 125px;
		width: 125px;
		background-color: #fff;
		background-image: url(RESOURCE/img/sylt-gray.svg);
		background-size: auto 75%;
		background-repeat: no-repeat;
		background-position: center;
		border-radius: 50%;
	}

	.box-inner {
		background: #fff;
		box-shadow: 0 3px 15px 0 rgb(0 0 0 / 10%);
		margin-bottom: 30px;
		border-radius: 5px;
		overflow: hidden;

		.img {
			img {
				width: 100%;
			}
		}

		.text-info {
			padding: 15px;

			h4 {
				margin: 0 0 15px 0;
				color: #333;
				text-transform: uppercase;
			}

			p {
				min-height: 190px;

				@media (max-width: 767px) {
					min-height: auto;
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					min-height: 120px;
				}

				@media all and (min-width: 992px) and (max-width: 1199px) {
					min-height: 290px;
				}
			}

			h6 {
				.btn-primary {
					padding: 0;
					max-width: 140px;
					width: 100%;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
}

.faq-list {
	h4 {
		margin: 0 0 15px 0;

		a {
			color: #3c3c3c;

			&:hover {
				text-decoration: underline !important;
			}
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0 0 30px 0;

		li {
			font-size: 16px;
			margin: 0 0 10px;
			position: relative;
			padding: 0 10px 0 20px;

			&:before {
				font-family: "Font Awesome 5 Pro";
				content: '\f105';
				width: 30px;
				margin: 0;
				position: absolute;
				left: 5px;
				display: block;
			}


			a {
				color: #3c3c3c;

				&:hover {
					text-decoration: underline !important;
				}
			}
		}
	}
}

.faq-horizontal {
	width: 100%;
	overflow: hidden;

	span {


		@media (min-width: 767px) {
			position: relative;
			z-index: 10;
			display: block;
			padding: 0 15px;
			word-break: break-word;
		}
	}

	dt {
		padding: 15px 15px 5px;
		font-size: 16px;

		@media (min-width: 767px) {
			font-size: 16px;
			float: left;
			width: 25%;
			clear: left;
			border: none;
			margin: 0;
			padding: 15px 0;
			margin-right: -100%;
			padding-right: 100%;
			-webkit-box-sizing: content-box;
			box-sizing: content-box;
		}

		&:nth-of-type(odd) {
			background: #eee;
		}
	}

	dd {
		padding: 5px 15px 15px;
		font-size: 16px;

		@media (min-width: 767px) {
			float: left;
			width: 75%;
			margin: 0;
			padding: 15px 0;
			padding-left: 100%;
			margin-left: -100%;
			-webkit-box-sizing: content-box;
			box-sizing: content-box;
			font-size: 16px;
		}


		&:nth-of-type(odd) {
			background: #eee;
		}
	}
}

.alertmsg {
	p {
		color: red;
	}
}

ul.static {
	font-size: 16px;
	font-family: var(--d-font3);
	color: #666;
	padding: 0 0 0 40px;

	li {
		margin-bottom: 5px;
	}

	ul {
		padding: 10px 0 5px 30px;
	}
}