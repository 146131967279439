@import "setup.css";

.header-style2 {
	.mainHeader {
		padding: 14px 0 !important;
		border-bottom: 3px solid #f9f9f9;
		background-color: rgba(255, 255, 255, 1) !important;

		.navbar-area {


			.logo {
				a {

					img {
						max-width: 230px !important;
						padding: 0 !important;

						@media (max-width: 991px) {
							max-width: 170px !important;
							padding: 0 !important
						}

						@media all and (min-width: 993px) and (max-width: 1199px) {

							max-width: 180px !important;
						}

					}

				}
			}


			.topNav {
				ul {
					li {
						a {
							text-decoration: inherit;
							text-shadow: none;
							color: var(--black);
						}
					}
				}
			}
		}
	}


	.vo-nav-bar.mainHeader {
		.nav-right {
			.fav-count {
				.far {
					color: var(--d-blue) !important;
				}
			}
		}
	}
}

.navbar-area {

	>.srch-btn {
		color: var(--primary) !important;



		i.fal,
		i.far {
			@media (max-width:992px) {
				display: none;
			}
		}
	}
}

.nav-right {
	.srch-btn {
		padding: 2px 8px;
	}
}

.vo-nav-bar {
	&.affix {
		.logo {
			img {
				max-width: 230px !important;
				padding: 0 !important;
			}
		}

		.nav-right {
			.fav-count {
				.far {
					color: var(--d-blue) !important;
				}
			}
		}
	}



	&.mainHeader {
		position: fixed;
		width: 100%;
		top: 0px;
		z-index: 50;
		display: flex;
		align-items: center;
		padding: 28px 0;
		transition: all ease-in .2s;
		background-color: rgba(255, 255, 255, 0);

		@media (max-width:992px) {
			padding: 14px 0 !important;
			border-bottom: 3px solid #f9f9f9;
			background-color: #fff !important;
		}

		.navbar-area {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 25px;

			@media (max-width:992px) {
				padding: 0;
				margin-left: 0;
				justify-content: center;
			}

			@media all and (min-width: 993px) and (max-width: 1199px) {
				padding: 0 0;
			}

			.srch-btn {
				font-size: 20px;
				color: var(--white);

				@media (max-width:992px) {
					margin-right: 5px;
					color: var(--primary);
				}
			}
		}

		.logo {
			a {
				width: 165px;

				@media (max-width:1024px) {
					max-width: 140px;
				}
			}

			img {
				max-width: 250px;
				width: 100%;
				background: #fff;
				padding: 15px;
				border-radius: 10px;

				@media (max-width: 991px) {
					max-width: 170px !important;
					padding: 0 !important;
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					max-width: 230px !important;
				}

				@media all and (min-width: 992px) and (max-width: 1023px) {
					max-width: 200px !important;
				}
			}

		}

		.nav-right {
			display: flex;
			justify-content: flex-end;

			@media (max-width: 991px) {
				position: absolute;
				right: 30px;
			}


			.fav-count {
				color: var(--secondary);
				position: relative;
				margin-left: 5px;
				top: 6px;


				.far {
					font-size: 24px;
					color: #fff;

					@media (max-width: 991px) {
						color: var(--d-blue) !important;
					}

				}

				.sub-count {
					display: block;
					position: absolute;
					top: -7px;
					left: 16px;
					width: 18px;
					height: 18px;
					background: red;
					background: var(--primary);
					color: var(--white);
					text-align: center;
					font-size: 12px;
					line-height: 18px;
					border-radius: 50%;
				}
			}
		}

		.topNav {
			.navbar {
				min-height: inherit;
				margin: 0;

				@media (max-width:992px) {
					position: inherit;
				}
			}

			.navbar-toggler {
				background: transparent;
				font-size: 24px;
				color: var(--grey2);
				padding: 0;
				border: none;
				box-shadow: none;
				display: none;

				@media (max-width:992px) {
					display: inline-block;
					position: absolute;
					left: 20px;
					top: 20px;

				}

				@media all (min-width: 768px) and (max-width: 991px) {
					top: 28px;
				}
			}



			ul {
				margin: 0;
				padding: 0px;

				&.two-col-drop {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					width: 440px;

					li {
						@media (min-width:993px) {
							width: 50% !important;
						}
					}
				}

				li {
					list-style: none;
					padding: 0px;
					margin: 0px 5px;

					@media (max-width:1240px) {
						margin: 0 2px;
					}

					a {
						font-size: 16px;
						color: var(--white);
						display: inline-block;
						padding: 6px 15px;
						background: transparent;
						text-align: left;
						text-transform: uppercase;
						font-weight: bold;
						text-shadow: 0 0 10px rgba(0, 0, 0, .3);
						border-radius: 3px;

						i {
							margin-left: 5px;
						}

						@media (max-width:1240px) {
							padding: 5px 10px;
							font-size: 14px;
						}

						@media (max-width:992px) {
							padding: 7px 0;

						}

						span {
							padding: 3px 5px;
							border-radius: 3px;
							background-color: var(--secondary);
							color: var(--white);
							display: inline-block;
							line-height: 13px;
							font-size: 14px;
							margin-left: 3px;
						}
					}

					&:hover {
						a {
							background-color: var(--primary);
							color: var(--white);

						}

						.dropdown-menu {
							display: block;
						}
					}

					.dropdown-menu {
						min-width: 260px;
						left: 0px;
						margin: 0px;
						padding: 0;
						background-color: var(--white);
						border: none;
						border-radius: 0 3px 3px 3px;
						margin-top: -1px;

						@media (max-width:992px) {
							ul {
								padding: 0;
							}
						}

						li {
							float: left;
							width: 100%;
							border-bottom: 1px solid rgba(255, 255, 255, .2);
							margin: 0px;
							padding: 8px 20px;

							&:last-child {
								border-bottom: none;
							}
						}

						a {
							font-size: 14px;
							display: block;
							padding: 0;
							background: transparent;
							text-shadow: none;
							color: #333;

							&:hover {
								color: var(--secondary);
							}
						}
					}
				}

			}
		}
	}

	&.affix {
		padding: 14px 0 !important;
		border-bottom: 3px solid #f9f9f9;
		background-color: rgba(255, 255, 255, 1);

		.navbar-area {

			.topNav {
				ul {
					li {
						a {
							text-decoration: inherit;
							text-shadow: none;
							color: var(--black);
						}
					}

					li {
						&:hover {
							a {
								color: #fff;
							}
						}

						ul {
							li {
								a {
									color: #000 !important;

									&:hover {
										color: var(--secondary) !important;
									}
								}
							}
						}
					}
				}
			}


		}
	}

	@media (max-width:992px) and (min-width: 768px) {
		.navbar-collapse.collapse {
			display: none !important
		}

		.navbar-collapse.collapse.in,
		.navbar-collapse.collapsing {
			display: block !important
		}
	}

	@media screen and (max-width: 992px) {

		.topNav {

			.navbar-collapse {
				position: absolute;
				left: 0px;
				right: 0px;
				top: 58px;
				background: var(--primary);
				padding: 0px;
				overflow: scroll !important;
				max-height: 450px;


				ul {
					magin: 0px;
					float: left;
					width: 100%;
					padding: 10px 15px;

					li {
						float: left;
						width: 100%;

						margin: 0px;

						a {
							color: #ffffff;
						}

						&:hover {
							a {
								color: #ffffff;
							}

							.dropdown-menu {
								display: none;
							}
						}

						&.open {
							.dropdown-menu {
								display: block;
								position: static;
							}
						}

						.dropdown-menu {
							position: static;
							float: none;
							width: auto;
							margin-top: 0;
							background-color: transparent;
							border: 0;
							box-shadow: none;

						}
					}
				}
			}
		}
	}
}

.mm-page {
	&.mm-slideout {
		z-index: inherit;
	}
}

@media all (min-width: 768px) and (max-width: 991px) {
	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly {
		left: 50px;
	}
}

@media (max-width: 992px) {

	.mobile-navigation {
		ul.mm-listview {
			li {

				&.last {
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;

					a {
						border: 1px solid var(--orangeColor);
						border-radius: 100px;
						text-transform: uppercase;
						padding: 10px 30px;
						max-width: 125px;
						font-size: 13px;
						margin: 22px 0 0 0;

						&:hover {
							background: var(--orangeColor);
							border-color: var(--orangeColor);
						}
					}
				}
			}
		}

	}

	.mm-menu_offcanvas {
		width: 100vw;
		min-width: 100vw;
		z-index: 51;
		max-width: 100vw;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout {
		-webkit-transform: translate3d(100vw, 0, 0);
		transform: translate3d(100vw, 0, 0);
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-wrapper__blocker.mm-slideout {
		-webkit-transform: translate3d(87vw, 0, 0);
		transform: translate3d(87vw, 0, 0);
		height: 50px;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly {
		height: 50px !important;
		width: 50px !important;
		clip-path: inherit !important;
		-webkit-clip-path: inherit !important;
		clip: inherit !important;
		font-size: 0;
	}

	.navbar-nav,
	.navbar-nav>li {
		float: none !Important;
		padding: 0 15px;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly:before {
		content: "\f410";
		font-family: 'Font Awesome 5 Free';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		color: #fff;
		padding: 7px;
		float: left;
	}

	.mm-menu_offcanvas {
		display: none;
	}

	.mm-wrapper_opening .mm-menu_offcanvas {
		display: block;
	}

	.mm-wrapper_opening .mm-page.mm-slideout {
		display: none;

	}

	.mm-wrapper_opened .main-footer {
		display: none;
	}

	.mm-navbar {
		background: none;
		border-bottom: 1px solid rgba(255, 255, 255, .5);
		padding-top: 50px;
		margin-bottom: 20px;
		padding-bottom: 10px;
	}

	.mm-navbar__title>span {
		color: var(--white);
		font-size: 20px;
		font-weight: bold;
	}

	.dropdown {
		i {
			display: none;
		}
	}



	.mm-panel {
		background-image: var(--mobileMenuImg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		margin: 0;
		position: relative;

		&:after {
			content: " ";
			display: block;
			width: 100%;
			height: 100%;
			background: var(--d-blue);
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			opacity: .7;
		}
	}

	.mm-listitem {
		text-align: center;
		font-size: 20px;
		color: var(--white);

		a {
			span {
				padding: 0 8px 2px;
				line-height: 1;
				font-size: 14px;
				border-radius: 3px;
				background-color: var(--primary);
				position: relative;
				top: -2px;
			}
		}


	}

	.mm-listitem:after {
		display: none;
	}

	.mm-listitem__btn {
		border: none !important;
		position: absolute;
		right: 0;
		top: 12px;
		color: var(--white);
	}

	.mm-btn_next {
		position: absolute;
		left: 0;
		width: 100%;
		height: 30px;
		top: 6px;

		&:after {
			right: 30px;
		}
	}


	.mm-btn:after,
	.mm-btn:before {
		border-color: var(--white);
	}

	.mm-listview {
		max-height: 75vh;
		padding-bottom: 35px;
	}


	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-wrapper__blocker.mm-slideout {
		-webkit-transform: translate3d(87vw, 0, 0);
		transform: translate3d(87vw, 0, 0);
		height: 50px;
		z-index: 51;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly {
		height: 50px !important;
		width: 50px !important;
		clip-path: inherit !important;
		-webkit-clip-path: inherit !important;
		clip: inherit !important;
		font-size: 0;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly:before {
		content: "\f410";
		font-family: 'Font Awesome 5 Free';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		color: #fff;
		padding: 7px;
		float: left;
	}
}

.search-bl-mob {
	@media (max-width: 991px) {
		margin: 30px 0 0 0;
	}

	.input-group.search {
		@media all and (min-width: 768px) and (max-width: 991px) {
			max-width: 350px;
			width: 100%;
			margin: 0 auto;
		}
	}
}