.main-footer {
	background-color: var(--white);
	border-top: 1px solid #333;
	padding-top: 80px;
	position: relative;

	.newsletter-post-bl {

		position: static;
		z-index: 5;
		width: 100%;
		padding: 0 20px;
		margin: -140px 0 0 0;

		@media (max-width: 767px) {}

		.contact-form {
			margin-bottom: 0;
			clear: both;

			@media (max-width: 767px) {
				padding: 0;
			}

			p:last-child {
				margin-bottom: 0;
			}

			p,
			.heading1 {
				color: var(--white);
			}
		}
	}




	.footer-cont {
		@media (max-width:992px) {
			padding-bottom: 0;
		}

		.row {
			display: flex;
			flex-wrap: wrap;
		}
	}

	.newslet-bl {
		background-color: var(--d-blue);
		padding: 15px;
		border-radius: 10px;
		max-width: 1210px;
		margin: 0 auto;
		text-align: center;

		@media (max-width:992px) {
			padding: 16px 20px 10px;
		}

		@media (max-width: 575px) {
			padding: 10px 10px 10px;
		}

		h4 {
			color: var(--secondary);
			font-size: 16px;
			margin-bottom: 25px;
		}

		h2 {
			color: var(--white);
			text-transform: uppercase;
			margin-bottom: 40px;
			font-size: 21px;

			@media (max-width:992px) {
				margin-bottom: 30px;
			}
		}

		.widt-cont-bl {
			background-color: var(--white);
			color: #01242f;
			border-radius: 30px;
			border: 4px solid var(--secondary);
			padding-left: 15px;
			display: flex;
			max-width: 420px;
			width: 100%;
			margin: 0 auto;

			@media (max-width:992px) {
				border: none;
			}

			.form-control {
				border: none;
				background-color: transparent;
				font-size: 16px;
				font-family: var(--d-font);
				height: inherit;
				padding: 10px 20px;
				color: #3a3a3a;


				@media (max-width:992px) {
					padding: 7px 0;
					font-size: 13px;
				}
			}

			.btn-secondary {
				padding: 12px 40px;
				border-radius: 30px;
				color: var(--white);

				@media (max-width:992px) {
					padding: 7px 20px;
					font-size: 14px;
				}

				@media (max-width:575px) {
					padding: 7px 10px;
				}


			}
		}
	}


	.newsletter-inner {
		display: flex;
		flex-wrap: wrap;
	}

	.newsletter-text {
		margin: 0 auto;
		position: relative;
		text-align: left;
		display: flex;
		align-items: center;
		padding: 0;
		width: 50%;

		@media (max-width: 767px) {
			width: 100%;
			margin: 0 0 20px 0;
		}

		h3 {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			min-width: 80px;
			height: 80px;
			background: #00aac5;
			border-radius: 100%;
			flex-direction: column;
			justify-content: center;
			color: #fff;
			margin: 0 20px 0 0;

			@media (max-width: 767px) {
				margin: 0 10px 0 0;
				font-size: 20px;
			}

			span {
				font-weight: 400;
				font-size: 15px;
			}
		}

		h4 {
			margin: 0;
			color: #fff;
			font-size: 20px;

			@media (max-width: 991px) {
				font-size: 18px;
			}

			@media (max-width: 767px) {
				font-size: 16px;
			}

			span {
				display: block;
				font-size: 14px;
				font-weight: 400;
				margin: 5px 0 0 0;
				opacity: 0.7;
			}
		}
	}

	.widgt-grey-bl {
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media (max-width: 767px) {
			width: 100%;
		}
	}

	.social-footer {
		margin-bottom: 0px;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width:992px) {
			text-align: center;
		}

		@media (max-width: 991px) {
			justify-content: flex-start;
			margin: 0 0 20px 0;
		}

		a {
			width: 30px;
			height: 30px;
			display: inline-block;
			margin: 0 3px;
			text-align: center;
			border-radius: 50%;
			font-size: 16px;
			text-decoration: none;
			color: #fff;
			color: var(--white);
			line-height: 30px;
			background-color: #2d2767;

			&:hover {
				background-color: var(--secondary);
				color: var(--white);
			}

			&:last-child {
				margin-right: 0;
			}

		}
	}

	.ft-logo {
		margin-bottom: 20px;


		@media (max-width: 991px) {
			text-align: left;
		}

		@media (max-width: 767px) {
			text-align: center;
		}

		img {
			@media (max-width: 991px) {
				max-width: 200px;
			}
		}
	}

	.footer-1 {

		iframe {
			border: 1px solid #2d2767;
			max-width: 260px;
			width: 100%;

			@media (max-width: 767px) {
				left: 0;
				right: 0;
				margin: 0 auto;
			}
		}

	}

	.footer-2 {}

	.footer-3 {}

	.footer-4 {
		ul {
			list-style: none;
			padding: 0;
			margin: 65px 0 0 0;

			@media (max-width: 991px) {
				margin: 10px 0 0 0;
			}

			li {
				display: flex;
				align-items: center;
				color: #03364d;
				color: var(--d-blue2);
				font-size: 16px;
				font-family: var(--d-font2);
				margin: 0 0 35px;
				padding: 0 0 0 45px;
				position: relative;

				.fa-circle {
					background-image: url(RESOURCE/img/sylt.svg);
					background-color: #000;
					width: 38px;
					height: 38px;
					border-radius: 100px;
					background-repeat: no-repeat;
					background-size: 11px;
					background-position: center;

					&:before {
						display: none;
					}
				}

				.fas {
					background-color: #2d2767;
					position: absolute;
					width: 38px;
					height: 38px;
					color: #fff;
					font-size: 21px;
					border-radius: 100px;
					display: flex;
					align-items: center;
					justify-content: center;
					left: 0;
				}


				a {}
			}
		}
	}


	.widget-bl {
		@media (max-width:992px) {
			margin-bottom: 20px;
			border-bottom: 1px solid #078dcc;
			padding-bottom: 25px;
			margin-bottom: 25px;
		}

		.widgt-title {
			font-family: var(--d-font);
			color: var(--d-blue2);
			margin-bottom: 40px;
			font-weight: bold;
			display: block;
			margin-top: -3px;

			@media (max-width:992px) {
				margin-bottom: 30px;
			}
		}
	}

	.blue-li {
		li {
			list-style: none;
			margin-bottom: 15px;

			a {
				color: var(--d-blue2);
				font-size: 16px;
				font-family: var(--d-font2);

				&:hover {
					color: var(--primary);
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.icon-white-li {


		@media (min-width:993px) {
			display: flex;
			flex-wrap: wrap;
		}

		li {
			list-style: none;
			margin-bottom: 22px;
			position: relative;
			padding-left: 35px;
			color: #3c3c3c;

			&:last-child {
				margin-bottom: 0;
			}

			i {
				font-size: 20px;
				opacity: 1;
				position: absolute;
				left: 0;
				top: 2px;
				color: #3c3c3c;

				&.fa-phone-alt {
					top: 5px;
				}
			}

			a {
				color: #3c3c3c;
				font-size: 16px;
				font-weight: 500;
				margin: 0;
				font-family: var(--d-font2);

				&:hover {
					color: var(--primary);
				}
			}

			h4 {
				color: #3c3c3c;
				font-size: 16px;
				font-weight: 500;
				margin: 0;
				font-family: var(--d-font2);
			}

			span {
				font-size: 14px;
				opacity: .5;
				display: block;

			}
		}
	}

	.footer-bottom {
		border-top: 1px solid #333;
		padding: 30px 0 0 0;

		@media (max-width: 992px) {
			border-top: 1px solid #078dcc;
		}

		.email {
			.footer-bottom-box {
				margin-bottom: 10px;

				@media (max-width: 767px) {
					margin-bottom: 15px;
				}

				h4 {
					padding-top: 8px;
					color: var(--d-blue2);

					a {
						color: var(--d-blue2);

						&:hover {
							color: var(--secondary);
						}
					}
				}
			}
		}

		h5 {
			color: var(--d-blue2);
			font-size: 15px;
			font-family: var(--d-font2);
			margin: 0 0 20px 0;
			text-align: center;

			@media (max-width: 991px) {
				text-align: left;
			}
		}

		.address {
			@media (max-width: 767px) {
				margin: 0 0 10px 0;
			}
		}

		.social {
			@media (max-width: 991px) {
				padding: 10px 25px 0 25px;
			}
		}

		.col-md-3 {
			position: relative;

			.footer-bottom-box {
				position: relative;
				padding: 0 0 0 50px;


				@media (min-width: 768px) {
					margin-bottom: 15px;
				}

				h4 {
					margin: 0;
					font-size: 15px;
					color: var(--d-blue2);
					font-weight: 500;
					font-family: var(--d-font2);
				}

				i {
					position: absolute;
					left: 5px;
					top: 2px;
					font-size: 14px;
					width: 30px;
					height: 30px;
					display: flex;
					-webkit-box-align: center;
					align-items: center;
					-webkit-box-pack: center;
					justify-content: center;
					border-radius: 100px;
					opacity: 1;
					color: #fff;
					background: #2d2767;
				}
			}
		}

		.social {
			h5 {
				@media (max-width: 767px) {
					text-align: center;
				}
			}

			.social-footer {
				@media (max-width: 767px) {
					justify-content: center;
				}
			}
		}
	}

	.copyright {
		padding-bottom: 20px;
		color: var(--black);
		opacity: .5;
		font-size: 16px;

		@media (max-width:992px) {
			text-align: center;
		}
	}
}